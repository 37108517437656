@import "../../node_modules/basiclightbox/dist/basicLightbox.min.css";

[data-lightbox] {
	position: relative;
}

.lightbox-close {
	position: absolute;
	top: -4rem;
	right: -1rem;
	z-index: 99;
	width: 4rem;
	height: 4rem;
}

.lightbox-close::before,
.lightbox-close::after {
		position: absolute;
		top: 50%;
		left: 25%;
		display: block;
		content: "";
		width: 50%;
		height: 2px;
		background: currentColor;
		transform: rotate(45deg);
}

.lightbox-close::after {
	transform: rotate(-45deg);
}

.basicLightbox__placeholder {
	width: 100%;
}

.newsletter-signup-success,
[class*="newsletter-signup-error"],
[data-newsletter="success"] .newsletter-signup-intro,
[data-newsletter*="error"] .newsletter-signup-intro,
[data-newsletter="success"] form {
	display: none;
}

[data-newsletter="success"] .newsletter-signup-success,
[data-newsletter="error-unsubscribed"] .newsletter-signup-error-unsubscribed,
[data-newsletter="error-exists"] .newsletter-signup-error-exists,
[data-newsletter="error-invalid"] .newsletter-signup-error-invalid,
[data-newsletter="error-unknown"] .newsletter-signup-error-unknown {
	display: block;
}

[data-newsletter] input[type="email"]:focus {
	outline: none;
}
